import './App.css';
import React, {Suspense, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { Redirect } from 'react-router';
import TextField from '@material-ui/core/TextField';
import {BrowserRouter as Router, Switch, Route,  useLocation, Link} from "react-router-dom";
//import { getAuth, signOut } from "firebase/auth";
import ReactWhatsapp from 'react-whatsapp';
import wpfavicon from "./assets/wpfavicon.png";


import { useMediaQuery } from 'react-responsive';

import './Headertop.css';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useQuery from './useQuery';

import { Avatar, Badge,  Button,  Checkbox,  InputAdornment, Modal } from '@material-ui/core';
import firebase from "firebase/app";

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';
import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { grey} from '@material-ui/core/colors';

import ReactGA, { set } from 'react-ga';

import { useHistory } from "react-router-dom";
import Pricing from './Pricing';
import Infoblog from './Infoblog';
import 'firebaseui/dist/firebaseui.css';
import {db, auth} from './Firebase.js';
import Successfulsubscription from './Successfulsubscription';
import Publicprofile from './Publicprofile';
import Blogposts from './Blogposts';
import detectBrowserLanguage from 'detect-browser-language'


//const stripePromise = loadStripe('pk_test_51KVrgDFxgzzZ1KrmS3462jIrVKkbsxxwoVwlSQgCjtJffeKQYcdBZxe3skKGrvb88GwFzBib7aDOMBHpFgyukmww00WGhjIOWp');


const StyledFirebaseAuth = React.lazy(() => import('react-firebaseui/StyledFirebaseAuth'));



const Herosection = React.lazy(() => import('./Herosection'));
const Describtioncontainer = React.lazy(() => import('./Describtioncontainer'));
const Sectiontitle = React.lazy(() => import('./Sectiontitle'));
const Twocolcontainer = React.lazy(() => import('./Twocolcontainer'));
const Footer = React.lazy(() => import('./Footer'));
const Sliderlist = React.lazy(() => import('./Sliderlist'));
const Controlpanel = React.lazy(() => import('./Controlpanel'));

const populartests = ["Scanning Electron Microscopy (SEM)","Tensile Test", "Transmission Electron Microscopy (TEM)", "Charpy Impact Test", "Fatigue Test", "JmatPro Calculation", "ThermoCalc Calculation", "Compression Testing"];
const popularteststr = ["Taramalı Elektron Mikroskobu (TEM)","Çekme Testi", "Geçirimli Elektron Mikroskobu (TEM)", "Çentikli (Charpy) Darbe Testi", "Yorulma Testi", "JmatPro Hesaplaması", "ThermoCalc Hesaplama", "Basma Testi"];

const materialtests = ["Scanning Electron Microscopy (SEM)", "Tensile Test", "Non-Destructive Testing", "Charpy V testing", "Prototype Production", "Fatigue Test"];

const popularequipments = ["Light Microscope", "Melting Furnace", "Magnetic Stirrer", "Pvd Coating Systems", "Precision Balance", "Salt Spray Testing", "Brinell Hardness Test"]

const biotechtests = ["Cytotoxicity", "In-vitro tests", "Spectroscopy", "Corrosion Tests"];
const simulationtests2 = ["JmatPro Calculation", "ThermoCalc Calculation", "Dilotmeter"];

const simulationtests = ["Finite Element Analysis", "CAD Designing", "CAM Designing", "3D Printing Simulation", "Material Modeling"];
const sectors = ["Biomedical", "Material", "Earth Science", "Quality Assurance","Data Science", "Robotics","Automotive","Aerospace"];




const Searchresult = React.lazy(() => import('./Searchresult'));
const Blog = React.lazy(() => import('./Blog'));
const Signup = React.lazy(() => import('./Signup'));
const Service = React.lazy(() => import('./Service'));
const Listings = React.lazy(() => import('./Listings'));
const Inbox = React.lazy(() => import('./Inbox'));

const Orders = React.lazy(() => import('./Orders'));


const Writemessage = React.lazy(() => import('./Writemessage'));
const Profile = React.lazy(() => import('./Profile'));
const Payment3 = React.lazy(() => import('./Payment3'));
const Order = React.lazy(() => import('./Order'));

//const Sell = React.lazy(() => import('./Sell'));
const Sell2 = React.lazy(() => import('./Sell2'));
const Blogwriting2 = React.lazy(() => import('./Blogwriting2'));





  


function getModalStyle() {
  const top = 50 ;
  const left = 50 ;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };

}




const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display:'flex',
    flexDirection:'column'
 
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  searchIcon: {

    color:'#FF5A5F',
   
  }
}));



const App =() => {

  

  const [cookiesaccepted, setCookiesaccepted]=useState(false);
  const [cookiesbanneropen, setCookiesbanneropen]=useState(false);


  const allplaceholders = {

    services : {
      en:"Services",
      tr: "Hizmetler"

    },

    equipments : {
      en:"Equipments",
      tr: "Ekipmanlar"

    },
    consumables : {
      en:"Consumables",
      tr: "Sarflar"

    },
     

    cookiestext: {
      en: "We are using cookies or similar technologies for technical purposes, to enhance site navigation, analyze site usage, and assist in our marketing efforts. For more details check",
      tr: "Pazarlama çalışmalarında yardımcı olması ve sitenin gerekli özellikleri sağlayabilmesi için çerezler ve benzeri teknolojileri kullanıyoruz. Detaylar için linki tıklayabilirsiniz:",
    },

    signup: {
      en: "Sign Up",
      tr: "Kayıt Ol",
    },
    login: {
      en: "Log In",
      tr: "Giriş",
    },
    startselling : {
      en: 'Start selling',
      tr: 'Satış yap',

    },
    messages : {
      en :'Messages',
      tr: 'Mesajlar',
    },

    orders : {
      en : 'Orders',
      tr : 'Siparişler',

    },
    sell : {
      en : 'Sell',
      tr : 'Satış',

    },
    listings : {
      en : 'Listings',
      tr : 'Listelemeler',

    },
    profile : {
      en : 'Profile',
      tr : 'Profil',

    },
    logout : {
      en : 'Logout',
      tr : 'Çıkış',

    },
   
    
    herosectiontitle : {
      en: 'An essential tool for researchers',
      tr:'Araştırmacılar için vazgeçilmez bir araç',
    }
  };




  const [language, setLanguage]=useState('en');

  const [cookiestext, setCookiestext]=useState('We are using cookies or similar technologies for technical purposes, to enhance site navigation, analyze site usage, and assist in our marketing efforts. For more details check');
  const [signupplaceholder, setSignupplaceholder]=useState('Sign Up');
  const [loginplaceholder, setLoginplaceholder]=useState('Log In');
  const [startsellingplaceholder, setStartsellingplacholder]=useState('Start selling');
  const [messagesplaceholder, setMessagesplacholder]=useState('Messages');
  const [ordersplaceholder, setOrdersplaceholder]=useState('Orders');
  const [sellplaceholder, setSellplaceholder]=useState('Sell');
  const [listingsplaceholder, setListingsplaceholder]=useState('Listings');
  const [profileplaceholder, setProfileplaceholder]=useState('Profile');
  const [logoutplaceholder, setLogoutplaceholder]=useState('Logout');

  const [herosectiontitleplaceholder, setHerosectiontitleplaceholder]=useState('An essential tool for researchers');

  useEffect(() => {
    const detectedlanguage = detectBrowserLanguage();
    if (detectedlanguage.slice(0, 2)==='tr')
    {setLanguage(detectedlanguage.slice(0, 2));}
    else
    {setLanguage('en')};
    
  }, []);
  
  useEffect(() => {
    setCookiestext(allplaceholders.cookiestext[language]);
    setSignupplaceholder(allplaceholders.signup[language]);
    setLoginplaceholder(allplaceholders.login[language]);    
    setStartsellingplacholder(allplaceholders.startselling[language]);
    setMessagesplacholder(allplaceholders.messages[language]);
    setOrdersplaceholder(allplaceholders.orders[language]);
    setSellplaceholder(allplaceholders.sell[language]);
    setListingsplaceholder(allplaceholders.listings[language]);
    setProfileplaceholder(allplaceholders.profile[language]);
    setLogoutplaceholder(allplaceholders.logout[language]);
    setHerosectiontitleplaceholder(allplaceholders.herosectiontitle[language]);


  }, [language]);




  useEffect(() => {

    if (cookiesaccepted) { 
    ReactGA.initialize('UA-219134899-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    }

    

  }, [cookiesaccepted]);




    
  const handlecookiesaccepted = () => {
    setCookiesaccepted(true);
    setCookiesbanneropen(false);
  };

  const handlecookiesrejected = () => {
    setCookiesaccepted(false);
    setCookiesbanneropen(false);
  };



  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1021px)'
  });

  const isTablet = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const isMobile = useMediaQuery({
    query: '(max-width:767px)'
  });






  const classes = useStyles();

  


  const [urltitlename, setUrltitlename] = useState(useQuery().get("title") || "");
  const [urlcategoryname, setUrlcategoryname] = useState( useQuery().get("category") || "services") ;


  const [filterkeyword, setFilterkeyword] = useState (urltitlename);
      const [rawlist, setRawlist] = useState ([]);
      const [newrawlist, setNewrawlist] = useState ([]);

      const [searchlist, setSearchlist]= useState([]);

      const [filterkeywordinurl, setFilterkeywordinurl]=useState(filterkeyword);




        function removeDuplicates(originalArray, prop) {
          var newArray = [];
          var lookupObject  = {};

          for(var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
          }

          for(i in lookupObject) {
              newArray.push(lookupObject[i]);
          }
          return newArray;
      };

      const autocompletelist = removeDuplicates(rawlist, language);
      
    
      const [type, setType] = useState(urlcategoryname);
      const [open, setOpen] = useState(false);

      const handletypeChange = (event) => {
        setType(event.target.value);


      };
      


      const handletypeClose = () => {
        setOpen(false);
      };
    
      const handletypeOpen = () => {
        setOpen(true);
      };
 
  
      const [titleplaceholder, setTitleplaceholder] = useState('Search for services for your research');

      const [oldrawlist, setOldrawlist]=useState([]);
     /* Bu kod daha önce kullanılan koddu. Alttakiyle değiştirdim.
        useEffect(() => {
        db.collection(type).onSnapshot(snapshot => {

          setRawlist(snapshot.docs.map(doc=> doc.data()));
          setType(type);
          if (language==='en') { 
            setTitleplaceholder(`Search for ${type} for your research`)

          }
          else { setTitleplaceholder(`Aramaya başla...`)
        }
        })
    }, [type, language]); 
*/
    useEffect(() => {
      db.collection(`${type.slice(0, -1)}keywords`).onSnapshot(snapshot => {

        setRawlist(snapshot.docs.map(doc=> doc.data()));
        setType(type);
        if (language==='en') { 
          setTitleplaceholder(`Search for ${type} for your research`)

        }
        else { setTitleplaceholder(`Aramaya başla...`)
      }
      })

  }, [type, language]);

  const [filterkeywordlist, setFilterkeywordlist]=useState([]);
  
  const [filteredkeywordinenglish, setFilteredkeywordinenglish]=useState('');

  //Alttaki useffectin görevi türkçe olarak aranan bir ürününn, urle ingilizce olarak gönderilerek databaseden çekmesini sağlamak. Üstteki ise arama çubuğunda autocomplete için liste oluşturmak.
useEffect(() => {
  db.collection(`${type.slice(0, -1)}keywords`).where(language, '==', filterkeyword)
  .get()
  .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          setFilterkeywordlist(doc.data());
          setFilteredkeywordinenglish(doc.id);
      });
  })
  .catch((error) => {
      alert("Sorry an error occured: ", error);
  });

}, [filterkeyword, language])


 

console.log('BURASI BAK');
console.log(autocompletelist);
console.log(newrawlist);
console.log(filteredkeywordinenglish); 

    useEffect(() => {
      if (urltitlename != "") {

        setTitleplaceholder(urltitlename)

       }

    }, [urltitlename]);
   

    const [modalStyle] = useState(getModalStyle);
    const [openLogIn, setOpenLogIn] = useState (false);
    const [opentos, setOpentos]=useState(false);

    const [user, setUser] = useState(null);
    const [username, setUsername] = useState('');











    const location = useLocation().pathname;
    console.log('url is' + {location});
    const uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // Redirect to /after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      
      signInSuccessUrl:   location ? `${location}` : ("/") ,
     
   

      // We will display Google and Facebook as auth providers.
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,

        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        
      ],
        // Terms of service url.
         tosUrl: 'info/terms-and-services',
         // Privacy policy url.
       privacyPolicyUrl: 'info/privacy-policy'


    };
    
  
    const [isSignedIn, setIsSignedIn] = useState(false);  // Local signed-in state.
    
 






      // Listen to the Firebase Auth state and set the local state.
      useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
          if (authUser) {
            setIsSignedIn(true);

            
            setUser(auth.currentUser);
            setUsername(auth.currentUser.displayName);
            db.collection("users").doc(auth.currentUser.uid).set({
              lastsignedin: auth.currentUser.metadata.lastSignInTime || null,
              useremail: auth.currentUser.email,
              creationtime: auth.currentUser.metadata.creationTime,
                      
                  }, { merge: true })
                  .then(() => {

                      console.log("Last signedin is saved. ");
                    
                  })
                  .catch((error) => {
                      alert("Error writing lastsignedin info: ", error);
                  });

          }
          else {
            setUser (false);
            setIsSignedIn(false);
          }
        } 
        )
        return () => { 
          unsubscribe();
        }
           
      }, [isSignedIn]);

      const [tosaccepted, setTosaccepted]=useState(true);

      useEffect((async) => {
        
         if (user) {
          db.collection("users").doc(auth.currentUser.uid).get().then((doc) => { if (doc.exists){
            // Convert to City object
            
             let newtosaccepted =  doc.data().tosaccepted;

            if (newtosaccepted === true)  {
              
              
                setOpentos(false);
              
           

            }
            else   {
              setTosaccepted(false);
                const timeout = setTimeout(() => {
               
                setOpentos(true);
              }, 1000)


            } 

           
          } else {




          }}).catch((error) => {
            console.log("Error getting document:", error);
          });

       
        }



      }, [user])
      

      const [numberofnewmessages, setNumberofnewmessages]=useState('');
      const [numberofneworders, setNumberofneworders]=useState('');


      useEffect(() => {

        if (user) { 
        db.collection('users').doc(user.uid).collection('inbox')
        .where("read", "==", false)
        .onSnapshot((querySnapshot) => {
            var readmessages = [];
            querySnapshot.forEach((doc) => {
              readmessages.push(doc.data().read);
            });
            readmessages.join(", ");
            console.log('buraya bakalım ne çıkacak', readmessages.length)
            setNumberofnewmessages(readmessages.length)
        });

        db.collection('users').doc(user.uid).collection('orders')
        .where("status", "==", 'Waiting')
        .onSnapshot((querySnapshot) => {
            var neworders = [];
            querySnapshot.forEach((doc) => {
              neworders.push(doc.data().status);
            });
            neworders.join(", ");
            console.log('buraya bakalım ne çıkacak', neworders.length)
            setNumberofneworders(neworders.length)
        });

  


      }}, [user])


        












      const history = useHistory();

      
  
      const handlelogout = (event) => {
        event.preventDefault();
        
        db.collection("users").doc(auth.currentUser.uid).update({
          lastsignedout: firebase.firestore.FieldValue.serverTimestamp(),
   
              }, { merge: true })
              .then(() => {

                  firebase.auth().signOut().then(() => {
                      window.location.reload()

                  }).catch((error) => {
                    // An error happened.
                  });
                  
                
              })
              .catch((error) => {
                  alert("Error writing signedout info: ", error);
              });
        
            

    


      };



  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },

  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      color: grey[900],
      '&:focus': {
        backgroundColor: theme.palette.secondary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.getContrastText(grey[900]),
        },
      },
    },
  }))(MenuItem);
  

    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handlemenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlemenuClose = () => {
      setAnchorEl(null);
    };









    console.log(username);
    console.log('okunmamış mesajlar');
    console.log(numberofnewmessages);

    console.log( user?.city);

    const [checked, setChecked] = React.useState(false);
    const [savetosbuttonstate, setSavetosbuttonstate] = useState(true);
    

  const handleChangeofchecktos = (event) => {
    setChecked(event.target.checked);

  };

useEffect(() => {


  if (checked === true) {
    setSavetosbuttonstate(false);
  }

  else if (checked !== true) {

    setSavetosbuttonstate(true);

  }

}, [checked])




const handlesavetosaccepted = (event) => {
  event.preventDefault();

     db.collection("users").doc(auth.currentUser.uid).set({
              tosaccepted: true
 
                  }, { merge: true })
                  .then(() => {

                      console.log("Last signedin is saved. ");
                    
                  })
                  .catch((error) => {
                      alert("Error writing lastsignedin info: ", error);
                  });

   setOpentos(false);

};
    const [isituser, setIsituser] = useState(auth.currentUser ? 'itisuser': 'none');
    const [headerisloadable, setHeaderisloadable] = useState(null);

  useEffect(() => {


    if ( auth.currentUser) 
    { setIsituser('itisuser');

    const timeout = setTimeout(() => {
      setHeaderisloadable(true)
    }, 0);
     
    }

    else if (!user && !isSignedIn) {
      
      setIsituser('itisguest')
      const timeout = setTimeout(() => {
        setHeaderisloadable(true)
      }, 400);
       


  }

  else { setHeaderisloadable(false)}

  }, [auth.currentUser])
  
 
  useEffect(() => {
    if (!user && !cookiesaccepted) 

    { 

      const timeout = setTimeout(() => {
        setCookiesbanneropen(true);
      }, 1500);

    
    }
  }, [user, cookiesaccepted]) 
  
  const [isHoveringwp, setIsHoveringwp] = useState(false);

  const handleMouseOverwp = () => {
    setIsHoveringwp(true);
  };

  const handleMouseOutwp = () => {
    setIsHoveringwp(false);
  };


  return (

   

    <Router> 



      <Suspense fallback={<div className='fallback'>  Labtors Loading   </div>  }>

      <div>


      <Modal
        open={openLogIn}
        onClose={() => setOpenLogIn(false)}
      >
            <Suspense fallback={<div>Loading...</div>}>
                <div style={{backgroundColor:'white', marginTop:'0px', paddingTop:'0px', display:'flex', flexDirection:'column'}}>
                  
                  <img src={require('./assets/logo2.png').default} style={{width:'180px', marginTop:'3%', margin:'auto', justifyContent:'center', objectFit:'contain'}} />
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                </div>

            </Suspense>
      </Modal>

      <Modal
        open={opentos}

      >
        <div style={{background:'linear-gradient(90deg, rgba(255,90,95,1) 23%, rgba(174,23,217,0.9925012241224614) 88%)', display:'flex', flexDirection:'column', fontFamily:'Montserrat', color:'white'}}> 
          
          <p style={{width:'90%', marginLeft:'auto', marginRight:'auto', display:'flex', justifyConten:'center' }}>Before completing your registration, you must accept the Labtors's terms and conditions and privacy policy. </p>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', color:'white', width:'90%'}}>
                 
              
              <Checkbox
                        checked={checked}
                        onChange={handleChangeofchecktos}
                        inputProps={{ 'aria-label': 'controlled' }}
                        style ={{
                          color: "white",
                        }}
                      />
               <p>I read and agreed the <a href="/info/terms-and-services"> terms and services </a> and <a href="/info/privacy-policy"> privacy policy </a>    </p>
          </div>

          <div style={{margin:'auto', display:'flex', justifyContent:'center'}}>
                  <Button 
                                      onClick={handlesavetosaccepted}
                                      variant="contained"
                                      color= "primary"
                                     
                                      startIcon={<SaveIcon />}
                                      disabled={savetosbuttonstate}
                                    >
                                    Save
                                  </Button>
          </div>


        </div>
      </Modal>

  
      { headerisloadable ? 
        <div className="App">
         
              <div className="header">
                <div className="headertop_main">
                      { isDesktopOrLaptop ? 
                      <Link  to={{
                        pathname: "/",
                
                      }}  className="headertop_logo" ></Link>

                        
                       :
                       <a href="/" className="headertop_logo_mobile" >   </a>

                      }
                      {/*'buraya sonra gel daha önce burada categoryselection dropdown menu vardı'*/}
                        {  (isituser=='itisuser') ?
                                      
                                    <div className="headertop_desktopmenu" style={{color:'black'}}>
                                        { isDesktopOrLaptop /* && <div className="whatsapp_button" onMouseOver={handleMouseOverwp} onMouseOut={handleMouseOutwp}>    <ReactWhatsapp style= {{margin:'auto', marginRight:'2%', fontSize:'16px', color: isHoveringwp ? 'white' :'#25D366', padding:'0px', fontFamily:'Montserrat', fontWeight:'bold', justifyContent:'center', cursor:'pointer',  backgroundColor:'transparent',  height:'30px', border:'none'}} number="1-415-338-9241" message={language!=='tr' ? 'Hello,' : 'Merhaba,'}> {language!=='tr' ? 'Contact' :'Mesaj'}</ReactWhatsapp> </div>  }                                                {/* <a style={{textDecoration:'none', whiteSpace: 'nowrap', fontFamily:'Montserrat', fontWeight:'bold', color:'#F42B2B', backgroundColor:'white', marginTop:'auto', marginBottom:'auto', marginRight:'5%', display: isDesktopOrLaptop ? 'flex' : 'none',  justifyContent:'center'}}  href='/sell'> 
                                                  {startsellingplaceholder}
                                                </a> */}
                                                  <Avatar onClick={handlemenuClick} alt = {username} src={user?.profilephoto || user?.photoURL}  className="header_avatar" />
                                                  <StyledMenu
                                                    id="customized-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handlemenuClose}
                                                  >
                                                                        
                                                
                                                
                                                  <a href = {`/inbox/123`} style={{textDecoration:'none'}} >
                                                      <StyledMenuItem >
                                                          <ListItemIcon>
                                                          <Badge color="secondary" badgeContent={numberofnewmessages}>
                                                            <MessageOutlinedIcon style={{color:'#212121'}} />
                                                          </Badge>
                                                            
                                                          </ListItemIcon>
                                                          <ListItemText  primary={messagesplaceholder} />
                                                        </StyledMenuItem>

                                                    </a>
                                                    <a href ="/orders" style={{textDecoration:'none'}} >
                                                      <StyledMenuItem>
                                                        <ListItemIcon>
                                                        <Badge color="secondary" badgeContent={numberofneworders}>
                                                          <NotificationsActiveOutlinedIcon style={{color:'#212121'}} fontSize="small" />
                                                          </Badge>
                                                        </ListItemIcon>
                                                        <ListItemText primary={ordersplaceholder}/>
                                                      </StyledMenuItem>
                                                    </a>
                                                    <a href ="/sell" style={{textDecoration:'none'}} >
                                                    <StyledMenuItem>
                                                      <ListItemIcon>
                                                        <AttachMoneyOutlinedIcon style={{color:'#212121'}} fontSize="small" />
                                                      </ListItemIcon>
                                                      <ListItemText primary={sellplaceholder}/>
                                                    </StyledMenuItem>
                                                    </a>
                                                    <a href ="/listings" style={{textDecoration:'none'}} >
                                                    <StyledMenuItem>
                                                      <ListItemIcon>
                                                        <StoreOutlinedIcon style={{color:'#212121'}} fontSize="small" />
                                                      </ListItemIcon>
                                                      <ListItemText primary={listingsplaceholder}/>
                                                    </StyledMenuItem>
                                                    </a>
                                                    <a href ="/profile" style={{textDecoration:'none'}} >
                                                    <StyledMenuItem>
                                                      <ListItemIcon>
                                                        <PersonOutlineOutlinedIcon style={{color:'#212121'}} fontSize="small" />
                                                      </ListItemIcon>
                                                      <ListItemText primary={profileplaceholder}/>
                                                    </StyledMenuItem>
                                                    </a>
                                                    <StyledMenuItem>
                                                      <ListItemIcon >
                                                        <InputOutlinedIcon style={{color:'#212121'}} fontSize="small" />
                                                      </ListItemIcon>
                                                      <ListItemText onClick={handlelogout} primary={logoutplaceholder}/> 
                                                    </StyledMenuItem>
                                                  </StyledMenu>
                                </div> 

                      : 

                      <>
                     
                      {isituser=='itisguest' ? 
                      
                        <div className="headertop_desktopmenu">
                                          { isDesktopOrLaptop ? 
                                          <>
                                          {/*<div className="whatsapp_button" onMouseOver={handleMouseOverwp} onMouseOut={handleMouseOutwp}>  <ReactWhatsapp style= {{backgroundImage:`url(${wpfavicon})`, margin:'auto', padding:'0px', justifyContent:'center', cursor:'pointer', backgroundSize:'contain',   backgroundRepeat:'no-repeat', backgroundColor:'transparent', width:'30px', height:'30px', border:'none'}} number="1-415-338-9241" message={language!=='tr' ? 'Hello,' : 'Merhaba,'} /> <ReactWhatsapp style= {{margin:'auto', marginRight:'2%', fontSize:'16px', color: isHoveringwp ? 'white' :'#25D366', padding:'0px', fontFamily:'Montserrat', fontWeight:'bold', justifyContent:'center', cursor:'pointer',  backgroundColor:'transparent',  height:'30px', border:'none'}} number="1-415-338-9241" message={language!=='tr' ? 'Hello,' : 'Merhaba,'}> {language!=='tr' ? 'Contact' :'Mesaj'}</ReactWhatsapp> </div>  

                                          {/* <button onClick={ () => setOpenLogIn(true) }  style={{backgroundColor:'#00CC80', color:'white'}} className="header_sign-in-up">{loginplaceholder}</button> */}
                                          <button onClick={ () => setOpenLogIn(true) } style={{color: true ? '#FF5A5F' : ' #F42B2B', backgroundColor:'white'}} className="header_sign-in-up">{loginplaceholder}</button>
                                            
                                          </>

                                            :
                                          <>
                                          
                                          <button onClick={ () => setOpenLogIn(true) } style={{color:'#F42B2B', backgroundColor:'white'}} className="header_sign-in-up">Sign In</button>

                                          
                                          </>


                                          }
                        </div> 
                        :

                        <div>Loading</div> }
                         </>
                          
                            }
                      
                  </div>
               
                
                
          
               
              <div className="header_bottom" style={{ fontFamily:'Montserrat', display:'flex', flexDirection: isDesktopOrLaptop ? 'row' : 'column', boxShadow: '1px 1px 5px 1px silver', border:'solid 1px silver', borderRadius:'20px', width: isDesktopOrLaptop ? '60%' : '90%', marginLeft:'auto', marginRight:'auto', justifyContent:'center', marginBottom:'2%', marginTop:'1%'}}>

                <div style={{ width: '90%', marginTop:'2%', marginBottom:'2%', justifyContent:'center', marginRight:'2%', paddingLeft:'0%', marginLeft:'2%'}}>
                      <Autocomplete

                          onChange={(event, newValue) => {
                            event.preventDefault();
                            setFilterkeyword(newValue);
                          }}

                          id="free-solo-demo"
                          freeSolo
                               
                          options={autocompletelist.map((option) => option[language])}
                          renderInput={(params) => (

                          <TextField
                              {...params} 
                              placeholder={titleplaceholder}
                              value={filterkeyword}
                  
                              InputProps={{ ...params.InputProps, disableUnderline: true,  startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon className={classes.searchIcon} />
                                </InputAdornment>
                              ), }}
                          />
                      )}
                      />
                
                </div>
                <div className="categoryselection" style={{display:'flex', height:'auto', width: isDesktopOrLaptop ? '33%' : '100%', padding:'0px', marginTop:'0px', marginBottom:'0px', justifyContent:'center', border:'solid 1px silver', backgroundColor:'#FF5A5F ', borderRadius:'20px'}}>
                        <FormControl sx={{ width: 1 }}>
                          <InputLabel  id="demo-simple-select-autowidth-label"></InputLabel>
                          <Select  style={{color:'white', fontFamily:'Montserrat', fontWeight:'bold', height:'100%',margin:'auto', justifyContent:'center', width:'145px'}}
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            open={open}
                            onClose={handletypeClose}
                            onOpen={handletypeOpen}
                            value={type}
                            onChange={handletypeChange}
                            autoWidth
                            disableUnderline = {true}
                            placeholder={type}
                          >
                    
                            <MenuItem  value={"equipments"}>{allplaceholders.equipments[language]}   </MenuItem>
                            <MenuItem value={"services"}>{allplaceholders.services[language]}  </MenuItem>
                            <MenuItem value={"consumables"}>{allplaceholders.consumables[language]}   </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                  {filteredkeywordinenglish ? <Redirect to={`/search?category=${type}&title=${filteredkeywordinenglish}&country=${null}&city=${''}&maxprice=${100000}&minprice=${0}&searchkeyword=${''}&searchinstandard=${''}`} /> : null }
             
                </div>
               
              </div>



            <Switch>
            

              <Route path= "/search"  >
                 <Suspense fallback={<p> </p>}>
                     <Searchresult user={user} type={type} filterkeyword={filteredkeywordinenglish} usertypedkeyword={filterkeyword} searchlist={searchlist} autocompletelist={autocompletelist} isDesktopOrLaptop={isDesktopOrLaptop} language={language}/>
                </Suspense>
                </Route>

              <Route path= "/blog/:blogId/:blogUrl" >
                    <Suspense fallback={<p> Loading </p>}>
                          <Blog/>
                    </Suspense>
              </Route>
              <Route path= "/blogposts/" >
                    <Suspense fallback={<p> Loading </p>}>
                          <Blogposts isDesktopOrLaptop={isDesktopOrLaptop} />
                    </Suspense>
              </Route>
              <Route path= "/:categoryName/:serviceTitle/:serviceId"  >
                  <Suspense fallback={<p> Loading </p>}>
                        <Service user={user} isDesktopOrLaptop={isDesktopOrLaptop} language={language}/>
                  </Suspense>
              </Route>
              <Route path= "/inbox/:msgId/" >
                <Suspense fallback={<p> Loading </p>}> 
                  { user ?     <Suspense fallback={<p> Loading </p>}>   <Inbox username={username} user={user} userphoto={user.photoURL} />   </Suspense>

                  : 
                  <Signup text="You have to sign in to see this page"/> 
                  }
                  </Suspense>
              </Route>
              <Route path= "/editservice/:itemId" >
                <Suspense fallback={<p> Loading </p>}>  
                      { user ?                  <Suspense fallback={<div> Loading </div>}>  <Sell2 language={language} isDesktopOrLaptop={isDesktopOrLaptop} username={username} user={user} userphoto={user.photoURL} autocompletelist={autocompletelist} />          </Suspense>
                      : 
                      <Suspense fallback={<div> Loading </div>}>   <Signup text="You have to sign in to see this page"/>  </Suspense>
                        }
                  </Suspense>
              </Route>
              <Route path= "/editequipment/:itemId" >
                <Suspense fallback={<p> Loading </p>}>  
                      { user ?                  <Suspense fallback={<div> Loading </div>}>  <Sell2 language={language}  isDesktopOrLaptop={isDesktopOrLaptop} username={username} user={user} userphoto={user.photoURL} autocompletelist={autocompletelist} />          </Suspense>
                      : 
                      <Suspense fallback={<div> Loading </div>}>   <Signup text="You have to sign in to see this page"/>  </Suspense>
                        }
                  </Suspense>
              </Route>
              <Route path= "/editconsumable/:itemId" >
                <Suspense fallback={<p> Loading </p>}>  
                      { user ?                  <Suspense fallback={<div> Loading </div>}>  <Sell2 language={language}  isDesktopOrLaptop={isDesktopOrLaptop} username={username} user={user} userphoto={user.photoURL} autocompletelist={autocompletelist} />          </Suspense>
                      : 
                      <Suspense fallback={<div> Loading </div>}>   <Signup text="You have to sign in to see this page"/>  </Suspense>
                        }
                  </Suspense>
              </Route>
              <Route path= "/publicprofile/:userId" >
              <Suspense fallback={<div> Loading </div>}>     { user  ?    
              
                  <Suspense fallback={<p> Loading </p>}>  <Publicprofile user={user} isDesktopOrLaptop={isDesktopOrLaptop} />      </Suspense>          

                  :    
                  <Suspense fallback={<div> Loading </div>}>   
                  <Signup text="You are not authorized"/>  </Suspense>
                    }
                </Suspense>
              </Route>
              <Route path= "/orders/" >
                 <Suspense fallback={<p> Loading </p>}>
                      { user ?                   <Suspense fallback={<p> Loading </p>}>       <Orders username={username} user={user} userphoto={user.photoURL} language={language} />                    </Suspense>

                      : 
                      <Suspense fallback={<p> You have to sign in </p>}>  <Signup text="You have to sign in to see this page"/>  </Suspense>
                    }
                  </Suspense>
              </Route>
              <Route path= "/listings/" >
                  <Suspense fallback={<div> Loading </div>}> 
                        { user ?     <Suspense fallback={<div> Loading </div>}>  <Listings  isDesktopOrLaptop={isDesktopOrLaptop} username={username} user={user} userphoto={user.photoURL} language={language} />                    </Suspense>

                        : 
                        <Suspense fallback={<div> You have to sign in </div>}>  <Signup text="You have to sign in to see this page"/>  </Suspense>
                      }
                  </Suspense>
              </Route>
              <Route path= "/sell/" >
                <Suspense fallback={<p> Loading </p>}>  
                      { user ?                  <Suspense fallback={<div> Loading </div>}>  <Sell2 language={language}  isDesktopOrLaptop={isDesktopOrLaptop} username={username} user={user} userphoto={user.photoURL} autocompletelist={autocompletelist} />          </Suspense>
                      : 
                      <Suspense fallback={<div> Loading </div>}>   <Signup text="You have to sign in to see this page"/>  </Suspense>
                        }
                  </Suspense>
              </Route>
              <Route path= "/profile/" >
                  <Suspense fallback={<p> Loading </p>}> 
                          { user ?                              <Suspense fallback={<div> Loading </div>}>    <Profile isDesktopOrLaptop={isDesktopOrLaptop} username={username} user={user} userphoto={user.photoURL} language={language} />                     </Suspense>

                          : 
                          <Suspense fallback={<div> Loading </div>}>   
                          <Signup text="You have to sign in to see this page"/>  </Suspense>
                        }
                    </Suspense>
              </Route>
              <Route path= "/blogwriting/" >
              <Suspense fallback={<div> Loading </div>}>     {  ((user) && (auth.currentUser.uid== "vObpAk6irqNuBAc9rVYEoOspI1t2" || auth.currentUser.uid== "W79rIEzCc6bJDnV7DWeB2dvVosD2" ||  auth.currentUser.uid=='vXCKjo99BuPSvfS3AOm4crGwopG3')) ?    <Suspense fallback={<p> Loading </p>}>  <Blogwriting2/>      </Suspense>          

                  :    
                  <Suspense fallback={<div> Loading </div>}>   
                  <Signup text="You are not authorized"/>  </Suspense>
                    }
                  </Suspense>
              </Route>
              <Route path= "/controlpanel/" >
              <Suspense fallback={<div> Loading </div>}>     {  ((user) && (auth.currentUser.uid== "vObpAk6irqNuBAc9rVYEoOspI1t2" || auth.currentUser.uid== "W79rIEzCc6bJDnV7DWeB2dvVosD2" ||  auth.currentUser.uid=='vXCKjo99BuPSvfS3AOm4crGwopG3')) ?    <Suspense fallback={<p> Loading </p>}>  <Controlpanel/>      </Suspense>          

                  :    
                  <Suspense fallback={<div> Loading </div>}>   
                  <Signup text="You are not authorized"/>  </Suspense>
                    }
                  </Suspense>
              </Route>


              <Route path= "/writemessage/" >
                  <Suspense fallback={<p> Loading </p>}>   
                    { user ?  
                  
                          <Suspense fallback={<div> Loading </div>}>   
                          <Writemessage isDesktopOrLaptop={isDesktopOrLaptop} user={user}/>
                          </Suspense>

                    : 
                    <Signup text="You have to sign in to see this page"/>  }
                </Suspense>

              </Route>

              <Route path= "/payment" >
              <Suspense fallback={<p> Loading </p>}> 
                          {  user ?
                          <Suspense fallback={<p> Loading </p>}>   

                                     <Payment3 isDesktopOrLaptop={isDesktopOrLaptop} user={user} language={language} />
                            </Suspense>

                          : 
                          <Suspense fallback={<p> Loading </p>}>   
                             <Signup text="You have to sign in to see this page"/>  
                          </Suspense>

                          }
                        
                        
                          
                 </Suspense>
              </Route>
              <Route path= "/order/:orderId" >
              <Suspense fallback={<p> Loading </p>}> 
                          { user ?  
                           <Suspense fallback={<p> Loading </p>}>   
                                     <Suspense fallback={<p> Loading </p>}>   
                                           <Order isDesktopOrLaptop={isDesktopOrLaptop} user={user} language={language} />
                                      </Suspense>
                            </Suspense>
                          : 
                          <Suspense fallback={<p> Loading </p>}>   
                             <Signup text="You have to sign in to see this page"/>  
                          </Suspense>

                        
                          }
                 </Suspense>
              </Route>
              <Route path="/pricing">
                          <Pricing user={user}/>
              </Route>
              <Route path="/subscribed">
                          <Successfulsubscription user={user} isDesktopOrLaptop={isDesktopOrLaptop}/>
              </Route>
              
              <Route path= "/info/:infoId/" >
                          <Infoblog/>
              </Route>
              <Route path="/">
                      <Suspense fallback={ <h1>An essential tool for researchers. </h1>}>

                          </Suspense>
                          <Suspense fallback={<div className="fallback"> <h2>An essential tool for researchers.  </h2> </div>}>

                               <Herosection language={language} title={herosectiontitleplaceholder}/> 

                          </Suspense>

                          <Suspense fallback={<div className="fallback"> <h2>Some popular services </h2> </div>}>

                               <Sectiontitle marginleft="2.5%" title={language==='tr' ? 'Bazı popüler hizmetler' : "Some popular services"  }/>
                          </Suspense>
                          <Suspense fallback={<div className="fallback"> Loading </div>}>
                          { language==='tr' ? 
                          <div style={{marginBottom:'2%', marginLeft:'2.5%'}}>
                          <Suspense fallback={<div className="fallback"> Loading </div>}>
                              <Sliderlist clickable={true} category='services' key={popularteststr.key}  list = {popularteststr} heightofslides="36vh" widthofslides = "70%" slidesperview = {isDesktopOrLaptop ? '4.5' : isTablet ? '2.1' : '1.5'} backgroundcolor = "#FF5A5F" />
                          </Suspense>
                          </div>
                          :
                          <div style={{marginBottom:'2%', marginLeft:'2.5%'}}>
                          <Suspense fallback={<div className="fallback"> Loading </div>}>
                              <Sliderlist clickable={true} category='services' key={populartests.key}  list = {populartests} heightofslides="36vh" widthofslides = "70%" slidesperview = {isDesktopOrLaptop ? '4.5' : isTablet ? '2.1' : '1.5'} backgroundcolor = "#FF5A5F" />
                          </Suspense>
                          </div>
                          }
                          </Suspense>
                          <Suspense fallback={<div className="fallback"> <h2>Some popular services </h2> </div>}>

                                <Sectiontitle marginleft="2.5%" title={language==='tr' ? 'Bazı popüler ekipman ve sarflar' : 'Some popular equipments and consumables'}/>
                              </Suspense>
                          <Suspense fallback={<div className="fallback"> Loading </div>}>
                          <div style={{marginLeft: isDesktopOrLaptop ? '5%' : '1%', marginBottom:'5%'}}>
                          <Suspense fallback={<div className="fallback"> Loading </div>}>
                              <Sliderlist clickable={true}  category='equipments' key={popularequipments.key} list={popularequipments} heightofslides="13vh" widthofslides = "90%" slidesperview = {isDesktopOrLaptop ? '3.3' : isTablet ? '2.6' : '1.7'} backgroundcolor = "#00A699"/>
                          </Suspense>
                          </div>
                          </Suspense>
                          <Suspense fallback={<div className="fallback"> Loading </div>}>
                          <Describtioncontainer language={language}/>
                          </Suspense>
                          <Suspense fallback={<h2> Loading </h2>}>

                          <Sectiontitle marginleft="5%" title={language==='tr' ? 'Bazı sektörler' : "Some of the sectors"} />
                          </Suspense>
                          <Suspense fallback={<div className="fallback"> Loading </div>}>
                          <div style={{marginLeft: isDesktopOrLaptop ? '5%' : '1%', marginBottom:'5%'}}>
                                  <Suspense fallback={<div className="fallback"> <p>Loading </p> </div>}>
                                      <Sliderlist clickable={false}  key={sectors.key}   list={sectors} heightofslides="24vh" widthofslides = "90%" slidesperview = {isDesktopOrLaptop ? '3.3' : isTablet ? '2.6' : '1.7'} backgroundcolor = "#9A8FFF"/>
                                  </Suspense>
                          </div>
                          </Suspense>

                          <Suspense fallback={<div className="fallback"> <h2>Loading </h2> </div>}>
                          <Twocolcontainer language={language}/>
                          </Suspense>
                          <Suspense fallback={<div className="fallback"> <h2>Loading </h2> </div>}>
                          <Footer language={language}/> 
                          </Suspense>

                          { cookiesbanneropen && !user ? 


                          <div style ={{
                                      display:'flex',
                                    flexDirection:'column',

                                     background:'linear-gradient(90deg, rgba(255,90,95,1) 0%, rgba(17,41,108,1) 43%, rgba(37,34,38,0.9925012241224614) 100%)',
                                      position: 'fixed',
                                      width: '100%',
                                      bottom: '10px', 
                                      backgroundColor:'black',
                                      zIndex:'2',
                                      fontFamily:'Poppins', color:'white',
                                      borderRadius:'10px'
                                      }}> 


                                      <div style={{marginTop:'3%', width:'90%', marginLeft:'auto', marginRight:'auto', justifyContent:'center'}}>
                                             <p style={{color:'white'}}> {cookiestext}
                                             <a style={{color:'red'}} href="/info/terms-and-services"> {language==='tr' ? 'Çerez Politikası': 'our Cookies Policy'} </a>
                                             </p>
                                      </div>
                                      <div style={{display:'flex', flexDirection:'row', marginLeft:'auto', marginRight:'auto', marginBottom:'3%', justifyContent:'center'}}>
                                      <Button 
                                                onClick={handlecookiesaccepted}
                                                variant="contained"
                                                color= "primary"
                                                style={{
                                                  marginRight:'5%'
                                                }}
                                              
                                                startIcon={<CheckIcon />}
                                              >
                                              {language==='tr'? 'Kabul' : 'Accept'} 
                                      </Button>


                                      </div>
                                    

                                   


                           </div>

                           :
                           null }

              </Route>
    

            </Switch>
        </div>

        : null } 
       
    </div>
    </Suspense>
    </Router>
  );
}

export default App;
