import React, { useState } from 'react'
import { useEffect } from 'react';

function Footer(props) {

    const placeholders = {
          blog : {
            en : 'Blog',
            tr : 'Blog',
      
          },
          pricing : {
            en : 'Pricing',
            tr : 'Fiyatlama',
      
          },
          terms : {
            en : 'Terms',
            tr : 'Şartlar',
      
          },
          privacy : {
            en : 'Privacy',
            tr : 'Gizlilik',
      
          },
          faq : {
            en : 'FAQ',
            tr : 'SSS',
          },
      };
      const [language, setLanguage]=useState(props.language || 'en');

      const [blogplaceholder, setBlogplaceholder]=useState('Blog');
      const [pricingplaceholder, setPricingplaceholder]=useState('Pricing');
      const [termsplaceholder, setTermsplaceholder]=useState('Terms');
      const [privacyplaceholder, setPrivacyplaceholder]=useState('Privacy');
      const [faqplaceholder, setFaqplaceholder]=useState('FAQ');

      useEffect(() => {
      
        setBlogplaceholder(placeholders.blog[language]);
        setPricingplaceholder(placeholders.pricing[language]);
        setTermsplaceholder(placeholders.terms[language]);
        setPrivacyplaceholder(placeholders.privacy[language]);
        setFaqplaceholder(placeholders.faq[language]);
    
      }, [language]);



    return (
        <div style={{width:'100%', backgroundColor:'#00A699', color:'white'}}>
            <div style={{display:'flex', flexDirection:'row', width:'60%', marginLeft:'auto', marginRight:'auto', justifyContent:'center'}}>
                <a style ={{marginRight:'5%', textDecoration:'none', color:'white'}} href="/blogposts"> <p style={{fontFamily:'Poppins', fontWeight:'bold'}}> {blogplaceholder} </p> </a>
                <a style ={{marginRight:'5%', textDecoration:'none', color:'white'}} href="/pricing"> <p style={{fontFamily:'Poppins', fontWeight:'bold'}}> {pricingplaceholder} </p> </a>
                <a style ={{marginRight:'5%', textDecoration:'none', color:'white'}} href="/info/terms-and-services"> <p style={{fontFamily:'Poppins', fontWeight:'bold'}}> {termsplaceholder} </p> </a>
                <a style ={{marginRight:'5%', textDecoration:'none', color:'white'}} href="/info/privacy-policy"> <p style={{fontFamily:'Poppins', fontWeight:'bold'}}> {privacyplaceholder} </p> </a>
                <a style ={{marginRight:'5%', textDecoration:'none', color:'white'}} href="https://labtors.com/FAQ"> <p style={{fontFamily:'Poppins', fontWeight:'bold'}}> {faqplaceholder} </p> </a>

            </div>
            <div style={{display:'flex', width:'80%', marginLeft:'auto', marginRight:'auto', justifyContent:'center'}}>
                    <p style={{fontFamily:'Montserrat'}}> ©2021, Labtors Inc, All rights reserved. </p>
            </div>

            
        </div>
    )
}

export default Footer
