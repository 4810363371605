import firebase from "firebase/app"
import 'firebase/auth';    
import 'firebase/storage';    
import 'firebase/database';   
import 'firebase/firestore';  
 

const firebaseApp = firebase.initializeApp ({
    apiKey: "AIzaSyBfQPgPpZUJ5DHYRiFcwv9Q7LZutk4na1E",
    authDomain: "labtors-889b8.firebaseapp.com",
    projectId: "labtors-889b8",
    storageBucket: "labtors-889b8.appspot.com",
    messagingSenderId: "663014603870",
    appId: "1:663014603870:web:0ba7314c107e2480cc48cf",
    measurementId: "G-S1PTHXX415"

});

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export {db, auth, storage};
