import React, { useEffect, useState } from 'react';
import './Pricing.css';
import { db} from './Firebase';
import { loadStripe } from '@stripe/stripe-js';

function Pricing(props) {



const expertplanid = 'prod_LXciR9YmIxQrYI';
const expertplanmonthly= 'price_1Kqa91FxgzzZ1KrmnQbhY1rI';
const expertplanyearly='price_1Kqa9CFxgzzZ1KrmrZnE9mQr';

const premiumid = 'prod_LXckeAgK5JDlPx';
const premiumplanmonthly= 'price_1Kqa8FFxgzzZ1Krm0jSu7Lww';
const premiumplanyearly='price_1Kqa63FxgzzZ1KrmwouUX03b';

const [givenplan, setGivenplan]=useState([]);

const [givenpriceid, setGivenpriceid]=useState('');

const [loading, setLoading]=useState(false);

const [upgradebutton, setUpgradebutton]=useState(false);

useEffect(() => {
  if (props.user )

  { 
    setUpgradebutton(true);

  }

  else if (!props.user) {
    

    setUpgradebutton(false);
  }

 
}, [props.user])


useEffect(() => {
  
  db.collection('products')
  .where('active', '==', true) 
  .get()
  .then((querySnapshot)=> {

    const products = {};
    querySnapshot.forEach(async (productDoc) => {

      products[productDoc.id]=productDoc.data();
      const priceSnap = await productDoc.ref.collection('prices').get();
      priceSnap.docs.forEach((price)=> {
          products[productDoc.id].prices = {
              priceId : price.id,
              priceData: price.data(), 

          };


      });

    });


  

  });

}, [])

useEffect(() => {
  db.collection('products')
  .doc('prod_LXciR9YmIxQrYI')
  .collection('prices')
  .doc('price_1Kqa91FxgzzZ1KrmnQbhY1rI')
  .get().then((doc) => { if (doc.exists){
      // Convert to City object
      

      // Use a City instance method
     
    } else {
      console.log("No such document!");
    }}).catch((error) => {
      console.log("Error getting document:", error);
    });

   
}, []);

const loadcheckout = async (priceId) => {

  const docRef = await db
  .collection('users')
  .doc(props.user.uid)
  .collection('checkout_sessions')
  .add({
    price: priceId,
    success_url: 'https://labtors.com/subscribed',
    cancel_url: window.location.origin,
  });
// Wait for the CheckoutSession to get attached by the extension
docRef.onSnapshot(async (snap) => {
  const { error, sessionId } = snap.data();
  if (error) {
    // Show an error to your customer and
    // inspect your Cloud Function logs in the Firebase console.
    alert(`An error occured: ${error.message}`);
    setLoading(false);

  }
  if (sessionId) {
    // We have a Stripe Checkout URL, let's redirect.
   
    setLoading(false);
    const stripe = await loadStripe('pk_test_51KVrgDFxgzzZ1KrmS3462jIrVKkbsxxwoVwlSQgCjtJffeKQYcdBZxe3skKGrvb88GwFzBib7aDOMBHpFgyukmww00WGhjIOWp');
    stripe.redirectToCheckout({sessionId})
    alert('You are being redirected to payment page')
  }
});

};

console.log('loading');

console.log(loading);


const handlesigningup = (priceId) => {


   setLoading(true);
   loadcheckout(priceId);
} ;

  return (

   <> 
   { !props.user ?
   <div  style={{display:'flex', marginLeft:'auto', marginRight:'auto', justifyContent:'center'}}> <h1>You have to first sign up to upgrade your plan to basic or premium plan </h1> </div>
  :
  null
  }
    <div className="pricing" >

      <div style={{background: 'rgb(2,0,36)', background: 'linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(82,240,193,1) 13%, rgba(185,112,139,1) 45%, rgba(188,45,130,1) 72%, rgba(255,2,88,1) 93%)   ' }}  className="pricing__planitem"  >

        <div  className="pricing__planitem_content">
          
              <h2 >Free</h2>
              <p>For individuals or new labtors that don't plan to give service.</p>

        </div>
        <div style={{display:'flex', marginLeft:'auto', marginRight:'auto', justifyContent:'center'}} >
                <h1>Free</h1>
         </div>
         <div >
                     <button  className="subscribe_button" style={{backgroundColor:'gray'}}>Sign Up</button>
         </div>

      </div>

      <div style={{background: 'rgb(2,0,36)', background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,2,88,0.9364788151588761) 0%, rgba(255,2,88,1) 31%, rgba(0,212,255,1) 100%' }}  className="pricing__planitem"  >
            <div className="pricing__planitem_content">

                    <h2>Expert</h2>
                    <p>For professional Labtors. Your listings will have a badge indicating that you are an Expert labtor and your listings will be placed in higher rankings. </p>


                </div>

                <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                         <h1>36$ </h1>
                         <p style={{marginTop:'auto', marginBottom:'auto', justifyContent:'center'}}> per Month </p>


              </div>
              <div >
              <button hidden={!upgradebutton} onClick={()=>{handlesigningup('price_1KzpeTFxgzzZ1Krmj3hMdimO')}}   className="subscribe_button" style={{backgroundColor:'red'}}>{loading ? 'Processing' : 'Upgrade to Expert'} </button>
             </div>
             
             <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                         <h1>or 360$ </h1>
                         <p style={{marginTop:'auto', marginBottom:'auto', justifyContent:'center'}}> per Year </p>


              </div>
              <div >
              <button hidden={!upgradebutton} onClick={()=>{handlesigningup('price_1Kzpf7FxgzzZ1Krm0KWfmu0F')}}  className="subscribe_button" style={{backgroundColor:'red'}}>{loading ? 'Processing' : 'Upgrade to Expert'} </button>
             </div>

      </div>

      <div  style={{border:'3px solid black', background: 'rgb(2,0,36)', background: '      linear-gradient(90deg, rgba(255,2,88,0.9364788151588761) 0%, rgba(0,212,255,1) 0%, rgba(82,168,240,1) 13%, rgba(156,83,246,1) 30%, rgba(219,31,243,1) 45%, rgba(219,76,211,1) 58%, rgba(200,18,190,1) 73%, rgba(172,0,163,1) 96%)' }} className="pricing__planitem"  >
           <div  className="pricing__planitem_content">
                  <h2>Premium</h2>
                 <p>For departments and organizations who have advanced needs. Your listings will be placed with the highest rankings and will have a chance to show yourself, your other listings and your website to the users.</p>

            
            </div>
            <div style={{display:'flex', flexDirection:'row', margin:'auto', justifyContent:'center'}}>
                         <h1>48$ </h1>
                        <p style={{marginTop:'auto', marginBottom:'auto', justifyContent:'center'}}> per Month </p>


             </div>

             <div>
             <button hidden={!upgradebutton} onClick={()=>{handlesigningup('price_1Kqa8FFxgzzZ1Krm0jSu7Lww')}}  className="subscribe_button" style={{backgroundColor:'black'}}>{loading ? 'Processing' : 'Upgrade to Premium'}</button>
             </div>
             <div style={{display:'flex', flexDirection:'row', margin:'auto', justifyContent:'center'}}>
                         <h1>or 480$ </h1>
                        <p style={{marginTop:'auto', marginBottom:'auto', justifyContent:'center'}}> per Year </p>


             </div>

             <div>
             <button hidden={!upgradebutton} onClick={()=>{handlesigningup('price_1Kqa63FxgzzZ1KrmwouUX03b')}}  className="subscribe_button" style={{backgroundColor:'black'}}>{loading ? 'Processing' : 'Upgrade to Premium'}</button>
             </div>


      </div>

     











    </div>

    </>
  )
}

export default Pricing