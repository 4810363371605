import React, { Suspense, useEffect, useState } from 'react'
import { db } from './Firebase';
import './Infoblog.css';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import {Helmet} from "react-helmet";


const Footer = React.lazy(() => import('./Footer'));


function Infoblog() {


    const [imgurl, setImgurl] = useState('');
    const [imgalt, setImgalt] = useState('');
    const [typeofpost, setTypeofpost] = useState('BLOG');
    const [categoryofpost, setCategoryofpost]=useState('');
    const [headline, setHeadline] = useState('');
    const [date, setDate] = useState('');
    const [intro, setIntro] = useState('');
    const [metadescribtion, setMetadescribtion] = useState('');

    const [blogtitle, setBlogtitle] = useState(''); 

    const [maintext, setMaintext] = useState('');

    let { infoId } = useParams();
    // const urlblogtitle = blogTitle.replace(/-/g, ' ');
    //console.log(blogTitle);
    




    useEffect(() => {
      
    
        db.collection('infos').doc(infoId).get().then((doc) => { if (doc.exists){
            // Convert to City object
            
            setImgurl(doc.data().imgurl);
            setImgalt(doc.data().imgalt);
            setTypeofpost(doc.data().typeofpost);
            setCategoryofpost(doc.data().categoryofpost);
            setHeadline(doc.data().headline);
            setDate('31 Jan 2022');
            setIntro(doc.data().intro);
            setMaintext(doc.data().post);
            setBlogtitle(doc.data().title);
            setMetadescribtion(doc.data().metadescribtion);
            // Use a City instance method
           
          } else {
            alert("No such document!");
          }}).catch((error) => {
            alert("Error getting document:", error);
          });

    }  , [infoId]);

    


    return (
      <>

        <Helmet>
            <title>{blogtitle}</title>
            <meta name="description" content={metadescribtion} />
        </Helmet>

        <div  className="infoblog_main">

                <h1 >{headline || 'Loading............'}</h1>
                <p style={{width:'90%', fontWeight:'light', color:'gray'}}>Last modified:{date}</p>
                <p style={{color:'gray', fontWeight:'bold', paddingLeft:'2%', marginTop:'0px', width:'90%'}}>{intro} </p>

                  <div> {parse(maintext)} </div>
         


        </div>
        <Footer/>
      
        
        </>

    )
}

export default Infoblog
