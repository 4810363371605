import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { db } from './Firebase';

function Successfulsubscription({user, isDesktopOrLaptop}) {

  const history = useHistory();


// alttaki kod subsribtion konusunda deneme amaçlı

const [subscriptionId, setSubscriptionId]=useState('');
const [statusofsubscriber, setStatusofsubscriber]=useState(false);
const [plan, setPlan]=useState('Free');
const [activatebuttonStatus, setActivatebuttonStatus]=useState(true);

useEffect((async) => {
    if (user?.uid) {

    db.collection('users').doc(user.uid).collection('subscriptions').get().then((querySnapshot) => { 
        querySnapshot.forEach((doc) => {
        console.log(doc.id);
        setSubscriptionId(doc.id);
        })
    
    }) }

}, [user]);



useEffect((async) => {
    if (user?.uid && subscriptionId !='') {

        db.collection('users').doc(user.uid).collection('subscriptions').doc(subscriptionId).get().then((doc) => { if (doc.exists){
            // Convert to City object
            
            
            setStatusofsubscriber(doc.data().status);
            setPlan(doc.data().role);
            // Use a City instance method
           
          } else {
            console.log("No such document!");
          }}).catch((error) => {
            console.log("Error getting document:", error);
          });
          

        }


}, [user, subscriptionId]);

const [planActivated, setPlanActivated]=useState('');

useEffect((async) => {
  if (user?.uid ) {

    db.collection('users').doc(user.uid).get().then((doc) => { if (doc.exists){
        // Convert to City object
        
        let usergetteddata = doc.data();
        setPlanActivated(doc.data().PlanActivated);
        // Use a City instance method
       
      } else {
        console.log("No such document!");
      }}).catch((error) => {
        console.log("Error getting document:", error);
      });
      

    }


}, [user])



  const currentDateTime = Date().toLocaleString()

    const handleActivateplan = () => { 

                if ((user.uid) && (user.uid != '') ) { 
                    setActivatebuttonStatus(false);

                db.collection('users').doc(user.uid)
                .set({ 
        
                    SubscriptionPlan: plan || 'Free',
                    SubscriptionStatus: statusofsubscriber || 'active',
                    PlanActivated : currentDateTime || null,
             
                },{ merge: true }).then(() => {
                    alert('Your plan is succesfully activated');
                    history.push("/profile");
                  
                })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
                

        }


    }

    const [loadpage,setLoadpage]=useState(false);

   
   useEffect(() => {

    const timeout = setTimeout(() => {
        setLoadpage(true);
    }, 1000)

  }, [])





  return (
    <div> 

      { plan ? 
        <div style={{width:'90%', display:'flex', padding:'5%',flexDirection:'column', margin:'auto', justifyContent:'center', textAlign:'center',  backgroundColor:'green', borderRadius:'8px', color:'white', fontFamily:'Montserrat'}}>
                <h1 style={{display:'flex', margin:'auto', justifyContent:'center'}}>You succesfully subscribed </h1>
                { !planActivated && loadpage ? 
                <h2 style={{display:'flex', margin:'auto', justifyContent:'center'}}> { `Now you can activate your ${plan} plan `} </h2>
                  :
                  null}

        </div>
           :
           null
           }

        <div style={{width:'70%', display:'flex', margin:'auto', justifyContent:'center'}}>
            { !planActivated && loadpage ? 
             <button onClick={handleActivateplan} style={{ cursor:'pointer', padding:'5%', whiteSpace:'nowrap',cursor:'pointer',margin:'0.5%',paddingBottom:'1%', paddingTop:'1%', fontFamily:'Poppins',  fontWeight: isDesktopOrLaptop ?'bold' : 'light',color: activatebuttonStatus ? 'white' : '#FF5A5F', backgroundColor: activatebuttonStatus ? '#FF5A5F' : 'white',  borderRadius:'8px', border:'red solid 1px'}}>{activatebuttonStatus ? `Activate the ${plan} plan` : 'Processing'} </button>
            :
            null
            }
        </div>
    
    </div>
  )
}

export default Successfulsubscription