import React, { useEffect, useRef, useState }  from 'react';
import './Searchresult.css';
import CurrencyFormat from 'react-currency-format';
import './Services.css';
import Skeleton from '@material-ui/lab/Skeleton';
import { db } from './Firebase';

function Services(props) {

    const [loadpage,setLoadpage]=useState(false);

   const title = props.title.replace(/[^a-zA-Z0-9]/g, '').replace(/\s+/g, '-').replace(/:/g, "-") || props.keyword.replace(/\s+/g, '-').replace(/:/g, "-").replace(/[^a-zA-Z0-9]/g, '') 
   
   useEffect(() => {

    const timeout = setTimeout(() => {
        setLoadpage(true);
    }, 1000)

  }, []);



  const ref = useRef(null);


  const [imagewidth, setImagewidth]=useState(200);
  const [imageheight, setImageheight]=useState(150);

  useEffect(() => {

    setImagewidth(Number(ref.current?.offsetWidth));
    setImageheight(Number(ref.current?.clientHeight));
    console.log(imagewidth);


}, [ref]);

const [sellertype, setSellertype]=useState('free');
const [buyertype, setBuyertype]=useState('free');

const [sellercommission, setSellercommission]=useState(15);
const [buyercommission, setBuyercommission]=useState(15);


useEffect((async) => {
    if (props.seller) {

    db.collection('users').doc(props.seller).get().then((doc) => { if (doc.exists){
        // Convert to City object
        
        
        setSellertype(doc.data().SubscriptionPlan);
        setSellercommission(doc.data().commissionRate || 15);

        // Use a City instance method
       
      } else {
        console.log("No such document!");
      }}).catch((error) => {
        console.log("Error getting document:", error);
      });
      

    }

    if (props.user)  {

        db.collection('users').doc(props.user.uid).get().then((doc) => { if (doc.exists){
            // Convert to City object
            
            
            setBuyercommission(doc.data().commissionRate || 15);
            setBuyertype(doc.data().SubscriptionPlan);

            // Use a City instance method
           
          } else {
            console.log("No such document!");
          }}).catch((error) => {
            console.log("Error getting document:", error);
          });




    }

}, [props.seller, props.user]);

console.log(imagewidth);
console.log(buyertype);
console.log(buyercommission);
console.log(sellercommission);

    return (

        <>

       <a href={`/${props.category.toLowerCase()}/${title.toLowerCase()}/${props.id}`} style={{textDecoration:'none', color:'black'}} > 

        <div className="services_container" ref={ref} style={{backgroundColor: props.arkaplanrengi, marginLeft:'2%'}}>
           
            {  loadpage ? <div className="services_avatar"  style={{backgroundImage: `url(${props.img})` || `url("https://firebasestorage.googleapis.com/v0/b/labtors-889b8.appspot.com/o/placeholder.jpg?alt=media&token=e50a61c3-cbc8-46e1-b989-1a3cb260b283")` || `url(${process.env.PUBLIC_URL + 'images/loading2.gif'})`, backgroundColor:'lightgray' }}>
                       { sellertype ? <p style={{width:'40%', textAlign:'center', backgroundColor: sellertype == 'Expert' ?  '#5BE567' : '#FF5A5F',  opacity:'0.8', color:'white', fontWeight:'bold', marginTop:'2%', marginBottom:'2%', marginLeft:'2%',  paddingBottom:'1%', borderRadius:'10px'}} >{sellertype}</p> : null}
            </div> : <div className="services_avatar"  style={{marginBottom:'2%', marginTop:'2%'}}> <Skeleton variant="rect" width={props.isDesktopOrLaptop ? 200 : imagewidth } height={props.isDesktopOrLaptop ? 150 : 200} /> </div>   }
            <div className="services_right">
                <div className="services_right_describtion" style={{fontFamily:'Montserrat'}}>
                   { loadpage ? <h4 style={{color:'black', fontWeight:'normal', fontSize:'14px', borderBottom:'0.5px solid lightgray', maxHeight:'22px'}}>{props.usertypedkeyword}</h4> : <Skeleton height={22} width={100} variant="text" />}
                    { loadpage ?  <h4 className="services_right_title" style={{color:'black', fontWeight:'normal', fontSize:'18px', fontFamily:'Montserrat', marginTop:'3%', maxHeight:'44px', overflow:'hidden'}}>{props.title}</h4> : <Skeleton  width={props.isDesktopOrLaptop ? 250: 150}  variant="text" />}
                    {loadpage ?   <h4 style={{marginTop:'3%',color:'black', fontWeight:'normal'}}> {props.standards}</h4> : props.standards!='' ? <Skeleton  width={80}  variant="text" /> : null}
                    <div style={{display:'flex', flexDirection:'row'}} className="services_right_bottom">
                    { loadpage ? 
                    
                   <> {  props.state  ?
                        <h4 style={{marginRight:'auto', marginTop:'auto', marginBottom:'auto', justifyContent:'center', color:'gray', fontWeight:'bold'}}> {props.city}, {props.state}, {props.country}</h4>  
                        :
                        <h4 style={{marginRight:'auto', marginTop:'auto', marginBottom:'auto', justifyContent:'center', color:'gray', fontWeight:'bold'}}> {props.city}, {props.country}</h4>  
                        }
                    </>

                    :
                    <div style={{marginRight:'auto', marginTop:'auto', marginBottom:'auto', justifyContent:'center'}}>  <Skeleton  width={140} variant="text" /> </div> }
                        <div className="services_price" style={{marginRight:'2%',marginLeft:'auto', display:'flex', flexDirection:'column', width:'20%'}}>
                           
                            {loadpage ? <>  <CurrencyFormat value={props.price*1.35} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} renderText={value => <p style={{fontWeight:'normal', textDecoration:'line-through', fontSize:'14px', fontFamily:'Montserrat', marginLeft:'auto', marginBottom:'0%', whiteSpace:'nowrap'}}>{value}</p>} /> <CurrencyFormat value={props.price*(100+buyercommission+sellercommission)/100} displayType={'text'} thousandSeparator={true} prefix={'$ '} decimalScale={2} renderText={value => <h3 style={{fontWeight:'bold', fontFamily:'Montserrat', marginLeft:'auto', marginBottom:'0%', whiteSpace:'nowrap'}}>{value}</h3>} /> </> : <div style={{marginLeft:'auto'}}><Skeleton height={35} width={50} variant="text" />  </div> }
                            {loadpage ? <h3 style={{fontWeight:'normal', marginLeft:'auto' }}> / {props.category=='services' ? 'hour' : 'item' }</h3> : <div style={{marginLeft:'auto'}}><Skeleton height={35} width={50} variant="text" />  </div> }
                        </div>
                    </div>
                </div>

            </div>
            
           
        </div>
        </a>

 </>

    )

}

export default Services
