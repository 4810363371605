import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import './Blogposts.css';
import { db } from './Firebase';
import Footer from './Footer';



function Blogposts(props) {


  const [blogposts, setBlogposts]=useState([]);

useEffect(() => {

  db.collection('blogposts').onSnapshot(snapshot => {
          
          
    setBlogposts(snapshot.docs.map(doc=> ({id: doc.id, blogpost: doc.data()})));

    

  })

}, [])


const ref = useRef(null);
const ref2 = useRef(null);

const [pageload, setPageload]=useState(false);
const [imagewidth, setImagewidth]=useState(500);
const [imageheight, setImageheight]=useState(500);
const [widthofmobile, setWidthofmobile]=useState(500);
const [heightofmobile, setHeightofmobile]=useState(500);

useEffect(() => {

  setImagewidth(Number(ref.current?.offsetWidth));
  setImageheight(Number(ref.current?.clientHeight));


}, [ref]);

useEffect(() => {

  setWidthofmobile(Number(ref2.current?.offsetWidth));
  setHeightofmobile(Number(ref2.current?.clientHeight));


}, [ref2]);


useEffect(() => {
if (props.isDesktopOrLaptop) 
{ 
const timeout = setTimeout(() => {
  setPageload(true);
}, 1800)
}

else {

  const timeout = setTimeout(() => {
    setPageload(true);
  }, 2200)

}
}, [])

console.log('mobile için veriler:')
console.log(heightofmobile);
console.log(widthofmobile);


  return (

    <>
    { props.isDesktopOrLaptop ? 
    <div className="blogpostsfordesktop"> 
    
    <div className="mainposts" ref={ref}>
      
       {    pageload ?  <div className="mainposts__left" style={{borderRadius:'10px', width:'60%',  marginLeft:'2%',objectFit:'contain',   cursor:'pointer',     backgroundRepeat  : 'no-repeat',
       backgroundPosition: 'center',  backgroundSize:'100%',  backgroundBlendMode:'darken',  minHeight:'400px', backgroundColor:'lightgray', backgroundImage:'url(https://firebasestorage.googleapis.com/v0/b/labtors-889b8.appspot.com/o/becomealabtor.jpg?alt=media&token=363a0e0f-8476-41da-a60f-09b53e8a4ae4)'}}>
            <div style={{marginLeft:'2%', marginTop:'2%', color:'purple', borderRadius:'10px', border:'2px solid purple', width:'10%', textAlign:'center'}}>
               <h4 style={{fontFamily:'Montserrat', fontWeight:'bold'}}>Blog</h4>

            </div>
            <div style={{marginLeft:'2%', marginTop:'auto', marginBottom:'2%', color:'white', borderRadius:'10px',  width:'100%', }}>
               <h2 style={{fontFamily:'Montserrat'}}>Why to become a labtor ? </h2>
               <p style={{fontFamily:'Montserrat'}}>Labtors is made by scientists for scientists. We know the challenges of conducting a research project. Labtors is a platform to help you to minimize these challenges.</p>

            </div>
        </div>
        :
        <div style={{marginLeft:'3%'}} > 
             <Skeleton variant="rect" width={imagewidth*0.6} height={400}/>
         </div>
        }
    

        { pageload ? 
        <div className="mainposts__right" style={{borderRadius:'10px', cursor:'pointer',   width:'30%',  marginLeft:'2%', marginRight:'2%',objectFit:'cover',        backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',  backgroundSize:'100% 100%',  backgroundBlendMode:'darken',  minHeight:'400px', backgroundImage:'url(https://firebasestorage.googleapis.com/v0/b/labtors-889b8.appspot.com/o/images%2Fmodelingphoto.jpg?alt=media&token=49e7f03c-feae-46d0-8370-fd9099d915a4)'}}>
           <div style={{marginLeft:'2%', marginTop:'2%', color:'yellow', borderRadius:'10px', border:'2px solid yellow', width:'20%', textAlign:'center'}}>
               <h4 style={{fontFamily:'Montserrat', fontWeight:'bold'}}>Blog</h4>

            </div>
            <div style={{marginLeft:'2%', marginTop:'auto', marginBottom:'2%', color:'white', borderRadius:'10px',  width:'100%', }}>
               <h2 style={{fontFamily:'Montserrat'}}>Thermodynamic Modeling</h2>
               <p style={{fontFamily:'Montserrat'}}>The computational power of personal computers has reached levels that can easily solve very complex mathematical problems. As a result, thermodynamic modelling of materials has become very accessible.  </p>

            </div>


        </div>
        :

        <div className="mainposts__right" style={{marginLeft:'3%'}}>
                  <Skeleton variant="rect" width={imagewidth*0.3} height={400}/>

          </div>

      }

    </div>

    <div className="allposts">
      <div>

    {blogposts.map(({id, blogpost}) =>
      <a href={`/blog/${id}/${blogpost.urlthreewords?.replace(/ /g,"-")}`} key={id} className="singleblogitem" >
        <div style={{width:'100%', objectFit:'contain', backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',  backgroundSize:'cover',  backgroundBlendMode:'darken', minHeight:'200px',  backgroundImage:` url(${blogpost.imgurl})` }}>
              <h4 style={{fontFamily:'Montserrat',width:'50%', padding:'2%', color:'white', border:'2px solid white', backgroundColor:'#00A699', borderRadius:'10px',fontWeight:'bold', display:'flex', margin:'auto', justifyContent:'center'}}>{blogpost.categoryofpost}</h4>
        </div>
        <div style={{fontFamily:'Montserrat'}}>
          <h3 style={{marginLeft:'1%', marginRight:'1%'}}>{blogpost.title}</h3>
          <p style={{marginLeft:'1%', marginRight:'1%'}}>{blogpost.intro} </p>
        </div>


      </a>
    )}
      </div>
      <div style={{display:'flex', flexDirection:'column-reverse'}}>

    {blogposts.map(({id, blogpost}) =>
      <a href={`/blog/${id}/${blogpost.urlthreewords.replace(/ /g,"-")}`} key={id} className="singleblogitem" style={{width:'75%', marginTop:'14%', marginBottom:'6%'}}>
        <div style={{width:'100%', objectFit:'contain', backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',  backgroundSize:'cover',  backgroundBlendMode:'darken', minHeight:'200px',  backgroundImage:` url(${blogpost.imgurl})` }}>
              <h4 style={{fontFamily:'Montserrat',width:'50%', padding:'2%', color:'white', border:'2px solid white', backgroundColor:'#FF5A5F', borderRadius:'10px',fontWeight:'bold', display:'flex', margin:'auto', justifyContent:'center'}}>{blogpost.categoryofpost}</h4>
        </div>
        <div style={{fontFamily:'Montserrat'}}>
          <h3 style={{marginLeft:'1%', marginRight:'1%', }}>{blogpost.title}</h3>
          <p style={{marginLeft:'1%', marginRight:'1%'}}>{blogpost.intro} </p>
        </div>


      </a>
    )}
            </div>


    </div>
    
    
    
    
    
    </div>
        :
     <div className="blogpostsformobile">
             <div className="allpostsformobile" ref={ref2}>
             {blogposts.map(({id, blogpost}) =>
                   <a href={`/blog/${id}/${blogpost.urlthreewords}`} key={id} className="singleblogitemformobile" style={{textDecoration:'none', color:'black'}}>
                     {  pageload ?   <div style={{width:'100%', objectFit:'contain', backgroundRepeat  : 'no-repeat',
                  backgroundPosition: 'center',  backgroundSize:'100%',  backgroundBlendMode:'darken', minHeight:'150px',  backgroundImage:` url(${blogpost.imgurl})` }}> 
                   
                        <h4 style={{fontFamily:'Montserrat', width:'40%', justifyContent:'center' ,padding:'2%', color:'white', marginTop:'1%', display:'flex', marginRight:'auto', backgroundColor:'#00A699', borderRadius:'10px',fontWeight:'bold'}}>{blogpost.categoryofpost}</h4>
                  </div>
                  :
                  <Skeleton  width={widthofmobile*0.85} height={150}  variant="rect" /> 
                  }
                  <div style={{fontFamily:'Montserrat'}}>
                    { pageload ? <h2 style={{marginLeft:'1%', marginRight:'1%'}}>{blogpost.title}</h2> :  <div style={{marginLeft:'1%', marginRight:'1%'}}> <Skeleton  width={240} height={50}  variant="text" /> </div>  }
                    { pageload ? <p style={{marginLeft:'1%', marginRight:'1%'}}>{blogpost.intro} </p> : <div style={{marginLeft:'1%', marginRight:'1%'}}> <Skeleton  width={widthofmobile*0.8} height={150}  variant="rect" /> </div>  }
                  </div>
                      </a>
                  
              )}
      </div> 
         
         
         
         </div>

    }
   { pageload ? <Footer/> : null}
    </>
  )
}

export default Blogposts