import React, { useEffect, useState } from 'react'
import { Chip, Card, CardActionArea, CardMedia, CardContent, Typography } from '@material-ui/core/';
import { styled } from '@material-ui/core/styles';

import { db } from './Firebase';
import Services from './Services';
import { Skeleton } from '@material-ui/lab';
import { useParams } from 'react-router-dom';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  
  }));

function Publicprofile({user, isDesktopOrLaptop}) {

    let { userId } = useParams();


    const [category, setCategory] = useState('Services');
    const [listinglists, setListinglists] = useState([]);
    const [interests, setInterests]=useState([]);

    const [loadpage,setLoadpage]=useState(false);

    
    useEffect(() => {
 
     const timeout = setTimeout(() => {
         setLoadpage(true);
     }, 1000)
 
   }, [])

   const [dbdescription, setDbdescription]=useState('');

   const [dbsmallintro, setDbsmallintro]=useState('smallintro');

   const [username, setUsername]=useState('');

   const [labtorimage, setLabtorimage]=useState('');



useEffect(() => {
  if (userId) {

    db.collection('users').doc(userId).get().then((doc) => { if (doc.exists){
        // Convert to City object
        
        

        setUsername(doc.data().username || doc.data().useremail );
        setDbdescription(doc.data().description || 'description');
        setDbsmallintro(doc.data().smallintro || 'smallintro');
        setLabtorimage(doc.data().profilephoto || doc.data().userimageurl )
        // Use a City instance method
       
      } else {
        console.log("No such document!");
      }}).catch((error) => {
        console.log("Error getting document:", error);
      });
      

    }
  }, [userId]); 




    useEffect(() => {

        if  (userId) { 
          db.collection('services').where("userid", "==", userId).onSnapshot(snapshot => {
  
              setListinglists(snapshot.docs.map(doc=> ({id: doc.id, service: doc.data()})));
              setCategory(category);
            
          })
  
      }



      }, [userId, category]);





        useEffect((async) => {
            if (userId) {
            db.collection('users').doc(userId).collection('interests').onSnapshot(snapshot => {
    
                setInterests(snapshot.docs.map(doc=> doc.data()));
                
            })
    
            }
        }, [userId]);

  

      
      console.log(interests)

  return (
      <> 

    <div style={{display:'flex', flexDirection: isDesktopOrLaptop ? 'row' : 'column', marginTop:'1%',justifyContent:'space-between', width:'100%', fontFamily:'Montserrat'}}>
        <div style={{margin:'2%', display:'flex', flexDirection:'column',  width:isDesktopOrLaptop ? '40%' : '90%'}}>
            
            <div style={{border:'1px solid lightgray', borderRadius:'8px', marginBottom:'2%'}}>
                     <div style={{marginTop:'1%'}}>
                            { loadpage ?  <img style={{ display:'flex', margin:'auto', justifyContent:'center', width: isDesktopOrLaptop ? '200px' : '100px', height:isDesktopOrLaptop ? '200px' : '100px', borderRadius:'50%', backgroundColor:'lightgray'}} src={labtorimage || 'https://firebasestorage.googleapis.com/v0/b/labtors-889b8.appspot.com/o/einstein2.jpg?alt=media&token=d8326103-897e-4e64-94d2-319de79827fa'} alt = {username} ></img>  : <div style={{display:'flex',margin:'auto', justifyContent:'center'}}> <Skeleton variant="circle" width={isDesktopOrLaptop ? 200 :100 } height={isDesktopOrLaptop ? 200 :100} /> </div> }     

                            </div>
                            <div style={{display:'flex', flexDirection:'column', margin:'auto', justifyContent:'center', width:'90%'}}>
                    
                                  { loadpage ? <h3 style={{display:'flex', marginLeft:'auto', marginRight:'auto', marginTop:'1%', justifyContent:'center', textAlign:'center'}}>{username}</h3> : <div style={{display:'flex', margin:'auto', justifyContent:'center'}}>  <Skeleton variant="text" width={100} height={25} /> </div>}
                                 {   loadpage ? <p style={{color:'gray',display:'flex', marginLeft:'auto', marginRight:'auto', marginTop:'1%', justifyContent:'center', textAlign:'center'}}> {dbsmallintro || 'small intro'} </p> : <div style={{display:'flex', flexDirection:'column', margin:'auto', justifyContent:'center'}}>  <Skeleton variant="text" width={200} height={25} />  </div>}
                                 {   loadpage ?
                                    <div style={{backgroundColor:'#FF5A5F', marginTop:'2%', marginBottom:'1%', borderRadius:'8px', padding:'2%'}}>
                                    <p style={{color:'white',display:'flex',margin:'auto', justifyContent:'center', textAlign:'center', fontWeight:'bold'}}> Professional Labtor </p>    
                               

                                    </div>
                                    :   <div style={{ marginTop:'2%', marginBottom:'1%', borderRadius:'8px', padding:'2%'}}> <Skeleton variant="rect" width={isDesktopOrLaptop ? 450 :300} height={isDesktopOrLaptop ? 50 : 50}/> </div> }
                            </div>


            </div>


        
           { loadpage ? 
           <div style={{border:'1px solid lightgray', borderRadius:'8px', padding:'2%', display:'flex', flexWrap:'wrap', listStyleType:'none'}}>
            { 
                        interests
                        .map((interest) =>

                       
                 
                      <ListItem key={interest.label}> 
                            <Chip variant="outlined" label={interest.label} />
                    </ListItem>
            )}

            </div>
            :
            <div style={{display:'flex', marginTop:'2%', margin:'auto',  justifyContent:'center'}}>  <Skeleton variant="rect" width={isDesktopOrLaptop ? 500 :300} height={isDesktopOrLaptop ? 50 : 200} /> </div>
          }
            <div style={{border:'1px solid lightgray', borderRadius:'8px', marginTop:'2%', marginBottom:'2%'}}>
                <h3 style={{display:'flex',margin:'auto', marginTop:'1%', justifyContent:'center', textAlign:'center'}}> Description</h3>
                <p style={{fontFamily:'Poppins', color:'gray', fontWeight:'lighter', width:'80%', margin:'auto', marginTop:'2%', marginBottom:'2%', justifyContent:'center', textAlign:'justify'}}> {dbdescription || 'Description'}</p>
            </div>


        </div>
        
 
        <div style={{width:isDesktopOrLaptop ? '55%' : '90%', border:'1px solid lightgray', borderRadius:'8px', display:'flex', marginLeft:'auto', marginRight:'auto', marginTop:'2%', flexDirection:'column', justifyContent:'center'}}>
            <h2 style={{margin:'auto', justifyContent:'center', marginTop:'2%', alignText:'center'}}>{`Some of ${username}'s Listings`} </h2>
        { 
                        listinglists
                        .map(({id, service}) =>
            <a href={`/services/${service.title.toLowerCase()}/${id}`} key={id}  style={{textDecoration:'none', width:'60%', marginRight:'auto', marginLeft:'auto', justifyContent:'center', marginTop:'3%', marginBottom:'2%'}}>

                     
            

                        <Card sx={{ maxWidth: isDesktopOrLaptop ? 345 : 450 }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            image={service.img}
                            alt="green iguana"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div" >
                            {service.keyword}
                            </Typography>
                            <Typography variant="body2" >
                            {service.title}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                  
                      
                       
                 </a>
                    )
                    }

                 </div>

                     






    </div>


</>
  )
}

export default Publicprofile